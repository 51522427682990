@import "~@/erp/styles/variables/variables.scss";











.divider {
  margin: 20px 0;
  display: flex;
  align-items: center;
  .sub_title {
    margin: 0 10px 0 20px;
  }
  .dashed {
    flex: 1;
    border-top: 1px dashed #ccc;
  }
}
